import { createSearchParams, useNavigate } from "react-router-dom"
import GroupSubGroupSelect from "../../components/group-subgroup-select"
import Grupo from "../../models/grupo"
import ProdutoFilter from "../../models/produto-filter"
import { useState } from "react"
import ProductsList from "../../components/products-list"
import styled from "styled-components"
import { setDisplayProductType } from "../../redux/slices/sistema"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"

const DivRoot = styled.div`
    display: flex;
    flex-direction: column;
`

interface SearchProductMobileProps{
    grupos: Array<Grupo>
}

const SearchProductMobile = (props: SearchProductMobileProps) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { grupos } = props
    const [filter,setFilter] = useState<ProdutoFilter>()   
    const {displayProductsType} = useAppSelector(state => state.sistema)
    

    return (
        <DivRoot>
            <GroupSubGroupSelect
                groups={grupos}
                selectedGroupId={filter?.groupId}
                selectedSubGroupId={filter?.subGroupId}
                onChangeSelectdGroupId={groupId => navigate({
                    pathname: `/products/search`,
                    search: createSearchParams({
                        searchText: filter?.searchText ?? '',
                        groupId: groupId ?? '',
                        subGroupId: groupId === '' ? '' : filter?.subGroupId ?? ''
                    }).toString()
                })}
                onChangeSelectdSubGroupId={subGrupoId => navigate({
                    pathname: `/products/search`,
                    search: createSearchParams({
                        searchText: filter?.searchText ?? '',
                        groupId: filter?.groupId ?? '',
                        subGroupId: subGrupoId ?? ''
                    }).toString()
                })}
            />
            <ProductsList
                items={[]}
                searchText={filter?.searchText}
                onSearch={text => navigate({
                    pathname: `/products/search`,
                    search: createSearchParams({
                        searchText: text,
                        groupId: filter?.groupId ?? '',
                        subGroupId: filter?.subGroupId ?? ''
                    }).toString()
                })}
                onShowDetail={pro => navigate(`/products/id/${pro.cadProdutoId}`)}
                onChangeDisplayType={ type => dispatch(setDisplayProductType(type))}
                displayType={displayProductsType??'list'}
            />
        </DivRoot>
    )
}

export default SearchProductMobile