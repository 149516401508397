
import { Provider } from 'react-redux';
import store from './redux/store/store';
import { defaultTheme } from './styles/theme';
import AppRoutes from './routes/AppRoute';

import GlobalStyles from './styles/GlobalStyles';
import { ThemeProvider } from 'styled-components';


function App() {
  return (
    <Provider store={store}>
    
      <ThemeProvider theme={defaultTheme}>
        <AppRoutes />
        <GlobalStyles />
      </ThemeProvider>
    
  </Provider>
  );
}

export default App;