import { createSlice } from "@reduxjs/toolkit"
const KEY_DISPLAY_PRODUCT_TYPE = 'KEY_DISPLAY_PRODUCT_TYPE'
interface InitialState{
    title?: string
    displayProductsType?: 'list' | 'column'
}

const initialState: InitialState = {
    displayProductsType : localStorage.getItem(KEY_DISPLAY_PRODUCT_TYPE) === 'column'?'column' :'list'
}

const sistemaSlice = createSlice(
    {
        name: 'sistema',
        initialState,
        reducers:{
            setSiteTitle: (state, action) => {
                state.title = action.payload
            },
            setDisplayProductType: (state, action ) => {
                state.displayProductsType = action.payload
                localStorage.setItem(KEY_DISPLAY_PRODUCT_TYPE, action.payload)
            }
        }
    }
)

export const { setSiteTitle, setDisplayProductType } = sistemaSlice.actions

export default sistemaSlice.reducer