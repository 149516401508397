import styled from "styled-components"

const DivRoot = styled.div<{ color?: string }>`
    width: 2px;
    background-color: ${({ theme, color }) => color ?? theme.palette.common.gray};
    height: calc(100% - 10px);
    margin: 5px;
`
interface VerticalLineProps {
    color?: string
}

const VerticalLine = (
    props: VerticalLineProps
) => {
    return (
        <DivRoot
            color={props.color}
        ></DivRoot>
    )
}

export default VerticalLine