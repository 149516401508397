import styled from "styled-components"
import Produto from "../../models/produto"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import VerticalLine from "../vertical-line"
import { urlApi } from "../../utils/ApiUtil"

const DivRoot = styled.div`
    display: flex;
    gap: 10px;
    padding: 0px 10px 10px 10px;
    border-bottom:  1px solid ${ ({theme}) => theme.palette.common.gray};
`

const DivBody = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;    
    justify-content: center;
`

const Thumbnail = styled.div`
     display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;    
    min-height: 150px;
    border: 1px solid ${({ theme }) => theme.palette.common.gray};
    padding: 10px;
    border-radius: 5px;
    font-size: 2rem;
    color: ${({ theme }) => theme.palette.common.gray};
    &:hover{
        filter: opacity(0.7);
    }
    cursor: pointer;
    transition: 500ms;

    @media (max-width: 1366px) {  
        width: 80px;
        min-height: 80px;
    }
`

const ThumbnailImg = styled.img`
    width: 130px;
    height: auto;    
    border-radius: 5px;
    @media (max-width: 1366px) {  
        width: 64px;        
    }
    
`



interface ProductDetailRowProps {
    item: Produto
    onShowDetail: (produto: Produto) => void
}
const ProductDetailRow = (props: ProductDetailRowProps) => {
    const { item, onShowDetail } = props
    return (
        <DivRoot>
            <Thumbnail
                onClick={ () => onShowDetail(item)}
            >
                {item.caminhoThumbnail ?
                    <ThumbnailImg src={`${urlApi}/${item.caminhoThumbnail}`} alt={item.caminhoThumbnail} />
                    :
                    <FontAwesomeIcon icon={faCamera} />
                }
            </Thumbnail>
            <VerticalLine />
            <DivBody>
                <div>
                    {item.nome}
                </div>
                <div>
                    {item.codigoPrincipal}
                </div>
            </DivBody>

        </DivRoot>
    )
}

export default ProductDetailRow