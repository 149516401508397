import styled from "styled-components"
import Grupo from "../../models/grupo"
import { useEffect, useState } from "react"

const DivRoot = styled.div`
    display: flex;
    gap: 10px;
    border-top: 1px solid ${({ theme }) => theme.palette.common.gray};
    flex-direction: row;
    @media (max-width: 600px) {  
        flex-direction: column;
    }

`

const DivSelect = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
`
const DivLabel = styled.div`
    display: flex;
`

const Label = styled.label`
    
`

const DivComponent = styled.div`
    display: flex;
`

const Select = styled.select`
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.palette.common.gray};
    border-radius: 5px;
`
interface GroupSubGroupSelectProps {
    groups?: Array<Grupo>
    selectedGroupId?: string
    selectedSubGroupId?: string
    onChangeSelectdGroupId : (id:string) => void
    onChangeSelectdSubGroupId : (id:string) => void
}
const GroupSubGroupSelect = (props: GroupSubGroupSelectProps) => {
    const { groups, selectedGroupId, selectedSubGroupId, onChangeSelectdGroupId, onChangeSelectdSubGroupId } = props
    const [selectedGroup, setSelectedGroup] = useState<Grupo>()

    useEffect(() => {
        if (!groups) {
            return
        }

        const selected = groups.filter(x => x.cadGrupoId === selectedGroupId)
        if (!selected.length) {
            setSelectedGroup(undefined)
            return
        }
        setSelectedGroup(selected[0])

    }, [groups, selectedGroupId])
    return (
        <DivRoot>
            <DivSelect>
                <DivLabel>
                    <Label>Grupo:</Label>
                </DivLabel>
                <DivComponent>
                    <Select
                        onChange={ e => {
                            onChangeSelectdGroupId(e.target.value)
                        }}
                    >
                        <option value=''>TODOS</option>
                        {groups?.map(group => (
                            <option key={group.cadGrupoId} selected={group.cadGrupoId === selectedGroupId} value={group.cadGrupoId}>
                                {group.nome}
                            </option>
                        ))}
                    </Select>
                </DivComponent>

            </DivSelect>
            <DivSelect>
                <DivLabel>
                    <Label>Sub-Grupo:</Label>
                </DivLabel>
                <DivComponent>
                    <Select
                        onChange={ e => {
                            onChangeSelectdSubGroupId(e.target.value)
                        }}
                    >
                        <option value=''>TODOS</option>
                        {selectedGroup?.subGrupos.map(subGrupo => (
                            <option key={subGrupo.cadSubGrupoId} selected={subGrupo.cadSubGrupoId === selectedSubGroupId} value={subGrupo.cadSubGrupoId}>
                                {subGrupo.nome}
                            </option>
                        ))}
                    </Select>
                </DivComponent>

            </DivSelect>

        </DivRoot>
    )
}

export default GroupSubGroupSelect