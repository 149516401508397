import styled from "styled-components"

const P = styled.p`
    margin: 0px 0px 5px 0px;
`

interface ParagraphProps {
    children: React.ReactNode
}
const Paragraph = (props: ParagraphProps) => {
    return(
        <P>
            {props.children}
        </P>
    )
}

export default Paragraph