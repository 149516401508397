import { DefaultTheme } from 'styled-components'

export const defaultTheme: DefaultTheme = {
    font: {
        family: {
            sanSerif: 'geomanist system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
            monospace: 'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;'
        }
    },
    palette: {
        common: {
            black: '#222831',
            white: '#ffffff',
            gray: '#cccccc'
        },
        primary: {
            main: '#ffff95',
            contrastText: '#000'
        },
        secondary: {
            main: '#ecba42',
            contrastText: '#ffffff'
        },
        danger: {
            main: '#e1001d',
            contrastText: '#ffffff'
        },
        warning: {
            main: '#ecba42',
            contrastText: '#ffffff'
        },
        success: {
            main: 'rgb(97, 162, 41)',
            contrastText: '#ffffff'
        },
        info: {
            main: '#457B9D',
            contrastText: '#ffffff'
        },
    }
}
