import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Produto from "../../models/produto"
import ErrorResponse from "../../models/ErrorResponse"
import { fetchProdutoByFilterAsync, fetchProdutoByIdAsync, fetchProdutoByInternalCodeAsync } from "../../services/produto-service"

interface InitialState{
    items?: Array<Produto>
    current?: Produto
    isFetching?: boolean 
    fetched?: boolean 
    error?: string
}

const initialState : InitialState = {}

export const fetchProdutosByFilter = createAsyncThunk(
    'produtoSlice/fetchProdutosByFilter',
    fetchProdutoByFilterAsync
)

export const fetchProdutoById = createAsyncThunk(
    'produtoSlice/fetchProdutoById',
    fetchProdutoByIdAsync
)

export const fetchProdutoByInternalCode = createAsyncThunk(
    'produtoSlice/fetchProdutoByInternalCode',
    fetchProdutoByInternalCodeAsync
)

const produtoSlice  = createSlice({
    name: 'produtoSlice',
    initialState,
    reducers: {
        clearData: state => {
            state.items = undefined
            state.isFetching = undefined
            state.fetched = undefined
            state.error = undefined
            state.current = undefined
        },
        clearCurrent: state => {
            state.current = undefined
        }
    },
    extraReducers: builder => 
        builder
            .addCase( fetchProdutosByFilter.pending, state => {
                state.fetched = false
                state.isFetching = true
            })
            .addCase( fetchProdutosByFilter.fulfilled, (state, action) => {
                state.fetched = true 
                state.isFetching = false
                state.items = action.payload
            })
            .addCase(fetchProdutosByFilter.rejected, (state, action) => {
                try{
                    const errorResponse: ErrorResponse = JSON.parse(action.error.message??"");
                    state.error = errorResponse.message;
                }
                catch
                {
                    state.error = action.error.message;
                }
                state.isFetching = false
                state.fetched = false
            })

            .addCase( fetchProdutoByInternalCode.pending, state => {
                state.fetched = false
                state.isFetching = true
            })
            .addCase( fetchProdutoByInternalCode.fulfilled, (state, action) => {
                state.fetched = true 
                state.isFetching = false
                state.current = action.payload
            })
            .addCase(fetchProdutoByInternalCode.rejected, (state, action) => {
                try{
                    const errorResponse: ErrorResponse = JSON.parse(action.error.message??"");
                    state.error = errorResponse.message;
                }
                catch
                {
                    state.error = action.error.message;
                }
                state.isFetching = false
                state.fetched = false
            })

            .addCase( fetchProdutoById.pending, state => {
                state.fetched = false
                state.isFetching = true
            })
            .addCase( fetchProdutoById.fulfilled, (state, action) => {
                state.fetched = true 
                state.isFetching = false
                state.current = action.payload
            })
            .addCase(fetchProdutoById.rejected, (state, action) => {
                try{
                    const errorResponse: ErrorResponse = JSON.parse(action.error.message??"");
                    state.error = errorResponse.message;
                }
                catch
                {
                    state.error = action.error.message;
                }
                state.isFetching = false
                state.fetched = false
            })
})

export const { clearData, clearCurrent } = produtoSlice.actions
export default produtoSlice.reducer