import styled from "styled-components"
import Grupo from "../../models/grupo"
import Title from "../title"
import Subgrupo from "../../models/subgrupo"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {  faMicrochip, faSquareMinus, faSquarePlus, faStar } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import LoadingSpinner from "../loading-spinner"

const DivRoot = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};    
    overflow: auto;
`

const DivItems = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
`

const Ul = styled.ul`
    list-style: none;
    margin: 0px;
    display: inline;
    margin-block: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding-inline-start: 0px;
    padding-inline-end: 0px;
`

const LinkS = styled(Link)`
    text-decoration: none;
    color: ${ ({theme}) => theme.palette.primary.contrastText};
    &:hover{
        filter: opacity(0.5);
    }
`

const LiGroup = styled.li`
    cursor: pointer;
    span:hover{
        filter: opacity(0.5);
    }
`

const Li = styled.li`
    padding-left: 2rem;
    cursor: pointer;
    &:hover{
        filter: opacity(0.5);
    }
`

const CollapseIcon = styled.span`
    color: ${({ theme }) => theme.palette.success.main};
    margin-right: 5px;
`

const FinalIcon = styled.span`
    color: ${({ theme }) => theme.palette.danger.main};
    margin-right: 5px;
`

interface CategoriesListProps {
    groups: Array<Grupo>
    isLoading?: boolean

}

interface GroupItemProps {    
    group: Grupo    
}
const GroupItem = (props: GroupItemProps) => {
    const [visible, setVisible] = useState<boolean>(true)
    const {  group } = props
    const { subGrupos: items} = group
    return (
        <LiGroup onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            if ( ! items || ! items.length){
                // navigate
            }
        }}>
            {(items && items.length > 0) ?
                <CollapseIcon
                    onClick={ e => {
                        e.stopPropagation()
                        e.preventDefault()
                        setVisible( ! visible)
                    }}
                >
                    <FontAwesomeIcon icon={visible ? faSquareMinus : faSquarePlus} />
                </CollapseIcon>
                :
                <FinalIcon
                    onClick={ e => {
                        // navigate
                    }}
                >
                    <FontAwesomeIcon icon={faMicrochip} />
                </FinalIcon>
            }
            <LinkS to={`/products/group/${group.cadGrupoId}`}>{group.nome} {items && items.length > 0 && <>({items.length.toLocaleString()})</>}</LinkS>
            {visible && items &&
                <Ul>
                    {items.map(subGrupo => (
                        <Li
                            key={subGrupo.cadSubGrupoId}
                            onClick={e => {
                                e.stopPropagation()
                            }}
                        >
                            <FinalIcon>                        

                        

                                <FontAwesomeIcon icon={faMicrochip} />
                            </FinalIcon>
                            <LinkS to={`/products/group/${group.cadGrupoId}/sbgroup/${subGrupo.cadSubGrupoId}`}>
                            {subGrupo.nome}
                            </LinkS>
                        </Li>
                    ))}
                </Ul>
            }
        </LiGroup>
    )
}

const CategoriesList = (props: CategoriesListProps) => {
    return (
        <DivRoot>
            <Title
                render="h3"
                title="Categorias"
                variant="normal"
            />
            {props.isLoading && 
                <LoadingSpinner />
            }
            <DivItems>
                <Ul>
                    {props.groups.sort( (a,b) => a.nome > b.nome? 1: -1) .map(group => (

                        <GroupItem
                            key={group.cadGrupoId}                            
                            group={group}
                        />
                    ))}
                </Ul>
            </DivItems>
        </DivRoot>
    )
}
export default CategoriesList