import styled from "styled-components"
import Produto from "../../models/produto"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import { urlApi } from "../../utils/ApiUtil"

const DivRoot = styled.div`
    display: flex;
    gap: 10px;    
    border:  1px solid ${({ theme }) => theme.palette.common.gray};
    flex-direction: column;    
    flex: 1;
    border-radius: 5px;        
`

const DivBody = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;    
    justify-content: center;
    font-size: 0.8rem;
    padding: 10px;
    border-top:  1px solid ${({ theme }) => theme.palette.common.gray};    
`

const DivHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;    
    width: 100%;
`

const DivFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.palette.common.gray};
    border-top:  1px solid ${({ theme }) => theme.palette.common.gray};    
`
const DivFooterCod = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
`


const Thumbnail = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;        
    padding: 10px;
    border-radius: 5px;
    font-size: 2rem;
    color: ${({ theme }) => theme.palette.common.gray};        
    transition: 500ms;
    flex: 1;
`

const ThumbnailImg = styled.img`
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    
    
`



interface ProductDetailProps {
    item: Produto    
}
const ProductDetailItem = (props: ProductDetailProps) => {
    const { item } = props
    return (
        <DivRoot>
            <DivHeader>
                <Thumbnail>
                    {item.caminhoThumbnail ?
                        <ThumbnailImg src={`${urlApi}/${item.caminhoFoto}`} alt={item.caminhoFoto} />
                        :
                        <FontAwesomeIcon icon={faCamera} />
                    }
                </Thumbnail>
            </DivHeader>
            <DivBody>
                <div>
                    {item.nome}
                </div>

            </DivBody>
            <DivFooter>
                CÓD.:
                <DivFooterCod>
                    {item.codigoPrincipal}
                </DivFooterCod>
            </DivFooter>

        </DivRoot>
    )
}

export default ProductDetailItem