import styled from "styled-components"
import Title from "../title"
import Paragraph from "../paragraph"

const DivRoot = styled.div`
    display: flex;
    padding: 10px;
    overflow: auto;
    flex-direction: column;
`
const About = () => {
    return (
        <DivRoot>
            <Title
                render="h2"
                variant="normal"
                title="Sobre"
            />
            <Paragraph>
                A empresa Eletrônica Circuíto  atua desde 1994 no comércio varejista de material eletrônico e elétrico, oferecendo produtos de alta qualidade com preços compatíveis ao mercado.
            </Paragraph>
            <Paragraph>
                No site você pode conferir nossos produtos e solicitar um orçamento.
                Desejamos uma boa visita ao site e sugerimos que visite presencialmente a loja.
            </Paragraph>
            <Paragraph>
                Temos o prazer em receber a sua visita.
            </Paragraph>

        </DivRoot>
    )
}

export default About