import styled from "styled-components"
import Title from "../title"
import Paragraph from "../paragraph"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCoffee } from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const DivRoot = styled.div`
    display: flex;
    padding: 10px;
    overflow: auto;
    flex-direction: column;
    @media (max-width: 600px) {          
        overflow: visible;
    }  
`
const WhatsAppIcon = styled.span`
    color: ${props => props.theme.palette.success.main};
    padding-left: 5px;
`
const Contact = () => {
    return (
        <DivRoot>
            <Title
                render="h2"
                variant="normal"
                title="Contato"
            />
            <Paragraph>
                Rua Pedro Pereira, 857 Centro -Fortaleza - CE
            </Paragraph>
            <Paragraph>
                FONE:(85) 3226.0181 - FAX: 3253.2061
                <WhatsAppIcon><FontAwesomeIcon icon={faWhatsapp} /></WhatsAppIcon> (85) 99838.9586
            </Paragraph>
            <Paragraph>
                eletronicacircuitoltda@gmail.com
            </Paragraph>

        </DivRoot>
    )
}

export default Contact