import styled from "styled-components"
import Produto from "../../models/produto"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import { urlApi } from "../../utils/ApiUtil"

const DivRoot = styled.div`
    display: flex;
    gap: 10px;    
    border:  1px solid ${({ theme }) => theme.palette.common.gray};
    flex-direction: column;    
    max-width: 190px;
    border-radius: 5px;
    @media (max-width: 1366px) {  
        width: 200px;
        max-width: 250px;
    }
    @media (max-width: 600px) {  
        max-width: 100%;
        width: 100%;
    }    
    @media (min-width: 1900px) {  
        max-width: 215px;        
        height: auto;
    }
   
`

const DivBody = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;    
    justify-content: center;
    font-size: 0.8rem;
    padding: 10px;
    border-top:  1px solid ${({ theme }) => theme.palette.common.gray};
`

const DivHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`

const DivFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.palette.common.gray};
    border-top:  1px solid ${({ theme }) => theme.palette.common.gray};
`
const DivFooterCod = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
`


const Thumbnail = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;    
    min-height: 150px;
    border: 1px solid ${({ theme }) => theme.palette.common.gray};
    padding: 10px;
    border-radius: 5px;
    font-size: 2rem;
    color: ${({ theme }) => theme.palette.common.gray};
    &:hover{
        filter: opacity(0.7);
    }
    cursor: pointer;
    transition: 500ms;
    @media (max-width: 1366px) {  
        width: 100px;      
        min-height: 100px;
    }

    @media (max-width: 600px) {  
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    

   
`

const ThumbnailImg = styled.img`
    width: 130px;
    height: auto;
    border-radius: 5px;
    @media (max-width: 1366px) {  
        width: 64px;        
    }
    @media (max-width: 600px) {  
        max-width: 100%;
        width: 100%;
        height: auto;
    }

   
    
    
`



interface ProductDetailRowProps {
    item: Produto
    onShowDetail: (produto: Produto) => void
}
const ProductDetailCol = (props: ProductDetailRowProps) => {
    const { item, onShowDetail } = props
    return (
        <DivRoot>
            <DivHeader>
                <Thumbnail
                    onClick={ () => onShowDetail(item)}
                >
                    {item.caminhoThumbnail ?
                        <ThumbnailImg src={`${urlApi}/${item.caminhoThumbnail}`} alt={item.caminhoThumbnail} />
                        :
                        <FontAwesomeIcon icon={faCamera} />
                    }
                </Thumbnail>
            </DivHeader>
            <DivBody>
                <div>
                    {item.nome}
                </div>

            </DivBody>
            <DivFooter>
                CÓD.:
                <DivFooterCod>
                    {item.codigoPrincipal}
                </DivFooterCod>
            </DivFooter>

        </DivRoot>
    )
}

export default ProductDetailCol