import styled from 'styled-components'
import logo from '../../assets/logo_v.png'
import { useAppSelector } from '../../redux/hooks'
import { Link } from 'react-router-dom'

const DivRoot = styled.div`
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    display: flex;
    padding: 10px;
    gap: 10px;
    height: 80px;   
    align-items : center ;
    border: 1px solid ${props => props.theme.palette.warning.main};
    border-bottom-right-radius: 10px;
    flex-direction: row;
    @media (max-width: 600px) {  
        flex-direction: column;
        height: auto;
    }
    
`

const Logo = styled.img`
    width: auto;
    height: 100%;
    @media (max-width: 600px) {  
       width: calc(100% - 20px);
       height: auto;
    }
`
const DivLog = styled.div`
    display: flex;
    height: 80px;
    @media (max-width: 600px) {  
        width: 100%;
        height: auto;
    }
`

const DivTitle = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    @media (max-width: 600px) {  
        text-align: center;
        border-top: 1px solid ${({theme}) => theme.palette.common.gray};
        padding: 10px;
    }  

`

export const Header = () => {

    const { title } = useAppSelector(state => state.sistema)

    return (
        <DivRoot>
            <DivLog>
                <Link to='/'>
                    <Logo src={logo} />
                </Link>
            </DivLog>
            <DivTitle>
                <h1>{title ?? 'ELETRÔNICA CIRCUITO'}</h1>
            </DivTitle>
        </DivRoot>
    )
}
