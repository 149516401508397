import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { setDisplayProductType, setSiteTitle } from "../../redux/slices/sistema"
import styled from "styled-components"
import AnimatePage from "../../components/animatePage"
import Title from "../../components/title"
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom"
import ProdutoFilter from "../../models/produto-filter"
import { fetchProdutosByFilter } from "../../redux/slices/produto"
import ProductsList from "../../components/products-list"
import GroupSubGroupSelect from "../../components/group-subgroup-select"
import { fetchSecoes } from "../../redux/slices/secao"
import Grupo from "../../models/grupo"

const DivRoot = styled.div`
    display: flex;
    flex: 1;
    padding: 5px;
    flex-direction: column;
    overflow: auto;
    @media (max-height: 625px) {  
        overflow: visible;
    }
    
`

const ProductsSearch = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter,setFilter] = useState<ProdutoFilter>()
    const [grupos, setGrupos] = useState<Array<Grupo>>([])
    const{ items, isFetching} = useAppSelector(state => state.produto)
    const { items: secoes } = useAppSelector( state => state.secao)
    const { displayProductsType } = useAppSelector(state => state.sistema) 

    
    useEffect(() => {
        if ( ! searchParams){
            return
        }

        let searchText: string| undefined
        let groupId: string | undefined 
        let subGroupId: string | undefined

        if ( searchParams.has("searchText")){
            searchText = searchParams.get("searchText")??undefined;
        }

        if ( searchParams.has("groupId")){
            groupId = searchParams.get("groupId")??undefined
        }

        if (searchParams.has("subGroupId")){
            subGroupId = searchParams.get("subGroupId")??undefined
        }

        setFilter({
            searchText,
            groupId,
            subGroupId
        })

    },[searchParams])
    
    useEffect(() => {
        if ( ! filter){
            return
        }

        dispatch(fetchProdutosByFilter(filter))
    },[filter])

    useEffect(() => {
        dispatch(setSiteTitle("ELETRÔNICA CIRCUITO - BUSCA DE PRODUTOS"))
        dispatch(fetchSecoes())
    }, [])

    useEffect(() => {
        if ( ! secoes){
            return
        }

        const grps : Array<Grupo> = []
        secoes.forEach( item => item.grupos?.forEach( grupo => grps.push(grupo) ))
        grps.sort( (a,b) => a.nome > b.nome ? 1 : -1)
        setGrupos(grps)

    },[secoes])
    return(
        <DivRoot>
            <AnimatePage>
                <Title
                    variant="large"
                    render="h2"
                    title='Busca de produtos'
                />                
            </AnimatePage>
            <GroupSubGroupSelect
                groups={grupos}
                selectedGroupId={filter?.groupId}
                selectedSubGroupId={filter?.subGroupId}
                onChangeSelectdGroupId={ groupId => navigate({
                    pathname: `/products/search`,
                    search: createSearchParams({
                        searchText: filter?.searchText??'',
                        groupId: groupId ?? '',
                        subGroupId: groupId === ''? '': filter?.subGroupId ?? ''
                    }).toString()
                })}
                onChangeSelectdSubGroupId={ subGrupoId => navigate({
                    pathname: `/products/search`,
                    search: createSearchParams({
                        searchText: filter?.searchText??'',
                        groupId: filter?.groupId ?? '',
                        subGroupId: subGrupoId ?? ''
                    }).toString()
                })}
            />
            <ProductsList
                items={items}
                searchText={filter?.searchText}
                onSearch={text => navigate({
                    pathname: `/products/search`,
                    search: createSearchParams({
                        searchText: text,
                        groupId: filter?.groupId ?? '',
                        subGroupId: filter?.subGroupId ?? ''
                    }).toString()
                })}
                onShowDetail={ pro => navigate(`/products/id/${pro.cadProdutoId}`)}
                isLoading={isFetching}
                onChangeDisplayType={ type => dispatch(setDisplayProductType(type))}
                displayType={displayProductsType??'list'}
            />
        </DivRoot>
    )
}

export default ProductsSearch