import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import styled from "styled-components"

const DivRoot = styled.div`
    display: flex;    
    flex: 1;    
`

const Form = styled.form`
    display:  flex;
    flex: 1;    
`

const DivBody = styled.div`
    display: flex;
    flex: 1;    
`

const InputSearch = styled.input`    
    width: 100%;
    border-top-left-radius : 5px;
    border-bottom-left-radius : 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 1px solid ${({ theme }) => theme.palette.common.gray};
    padding: 0px 10px;
   
`

const ButtonSearch = styled.button`
    border: 1px solid ${({ theme }) => theme.palette.common.gray};
    border-top-right-radius : 5px;
    border-bottom-right-radius : 5px;
    padding: 10px;
    color: ${({ theme }) => theme.palette.info.main};
    cursor: pointer;
    &:hover{
        filter: opacity(0.5);
    }
`
interface ProductSearchProps{
    onSearch: (text:string) => void
    searchText?: string
    isLoading?: boolean
}
const ProductSearch = (props: ProductSearchProps) => {
    const{ onSearch, searchText, isLoading } = props
    const [text, setText] = useState<string>()
    useEffect(() => {
        if ( searchText ){
            setText(searchText)
        }
    },[searchText])
    return (
        <DivRoot>
            <Form
                onSubmit={ e => {
                    e.preventDefault()
                    if ( ! text ){
                        return
                    }
                    onSearch(text)
                }}
            >
                <DivBody>
                    <InputSearch
                        placeholder="DESCRIÇÃO OU CÓDIGO"
                        required
                        value={text}
                        onChange={e => setText(e.target.value)}
                        disabled={isLoading}
                        autoFocus
                    />
                </DivBody>
                <ButtonSearch
                    type="submit"
                    disabled={isLoading}
                >
                    <FontAwesomeIcon
                        icon={faSearch}
                    />
                </ButtonSearch>
            </Form>

        </DivRoot>
    )
}

export default ProductSearch