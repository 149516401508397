import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Secao from "../../models/secao";
import { fetchSecoesAsync } from "../../services/secao-service";
import ErrorResponse from "../../models/ErrorResponse";

interface InitialState{
    items?: Array<Secao>
    isFetching?: boolean 
    fetched?: boolean 
    error?: string
}

export const fetchSecoes = createAsyncThunk(
    'secaoSlice/fetchSecoes',
    fetchSecoesAsync

)

const initialState : InitialState = {}


const secaoSlice  = createSlice({
    name: 'secaoSlice',
    initialState,
    reducers: {
        clearData: state => {
            state.items = undefined
            state.isFetching = undefined
            state.fetched = undefined
            state.error = undefined
        }
    },
    extraReducers: builder => 
        builder
            .addCase( fetchSecoes.pending, state => {
                state.fetched = false
                state.isFetching = true
            })
            .addCase( fetchSecoes.fulfilled, (state, action) => {
                state.fetched = true 
                state.isFetching = false
                state.items = action.payload
            })
            .addCase(fetchSecoes.rejected, (state, action) => {
                try{
                    const errorResponse: ErrorResponse = JSON.parse(action.error.message??"");
                    state.error = errorResponse.message;
                }
                catch
                {
                    state.error = action.error.message;
                }
                state.isFetching = false
                state.fetched = false
            })
})

export const { clearData } = secaoSlice.actions
export default secaoSlice.reducer