import { combineReducers } from 'redux'
import sistema from '../slices/sistema'
import secao from '../slices/secao';
import grupo from '../slices/grupo';
import subGrupo from '../slices/sub-grupo';
import produto from '../slices/produto';

const rootReducer = combineReducers({
    sistema,
    secao,
    grupo,
    subGrupo,
    produto
})

export default rootReducer;