import { Route, Routes } from "react-router-dom"
import Layout from "../pages/Layout"
import Home from "../pages/Home"
import ProdutctsGroup from "../pages/ProductsGroup"
import ProductsSearch from "../pages/ProductsSearch"
import ProductsDetail from "../pages/ProductsDetail"

const AppRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<Layout />} >
               <Route path='/' element={<Home />} />
               <Route path='/products/group/:grpId' element={<ProdutctsGroup />} />
               <Route path='/products/group/:grpId/sbgroup/:sgrId' element={<ProdutctsGroup />} />
               <Route path='/products/search' element={<ProductsSearch />} />
               <Route path='/products/id/:id' element={<ProductsDetail />} />
               <Route path='/product/:internalCode' element={<ProductsDetail />} />
               <Route path='*' element={<>NOT FOUND </>} />
            </Route>
        </Routes>
    )
}

export default AppRoutes