import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { setSiteTitle } from "../../redux/slices/sistema"
import styled from "styled-components"
import About from "../../components/about"
import Contact from "../../components/contact"
import AnimatePage from "../../components/animatePage"
import SearchProductMobile from "./SearchProductMobile"
import Grupo from "../../models/grupo"
import ProductSearch from "../../components/product-search"
import { createSearchParams, useNavigate } from "react-router-dom"
import Title from "../../components/title"

const DivRoot = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
`
const DivBody = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    flex-wrap: wrap;
    flex-grow: 1;
`

const DivMenu = styled.div`
    display: flex;
    padding: 10px;
    border-bottom: 1px solid ${({theme}) => theme.palette.common.gray};
    flex-direction: column;    
    @media (max-width: 600px) {  
        display: none;
    }
`

const DivMenuMobile = styled.div`
    display: none;
    @media (max-width: 600px) {  
        display: flex;
    }

`


const Home = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { items: secoes } = useAppSelector(state => state.secao)
    const [grupos, setGrupos] = useState<Array<Grupo>>([])

    useEffect(() => {
        dispatch(setSiteTitle("ELETRÔNICA CIRCUITO"))
    }, [])

    useEffect(() => {
        if (!secoes) {
            return
        }

        const grps: Array<Grupo> = []
        secoes.forEach(item => item.grupos?.forEach(grupo => grps.push(grupo)))
        grps.sort((a, b) => a.nome > b.nome ? 1 : -1)
        setGrupos(grps)

    }, [secoes])

    return (
        <DivRoot>
            <DivMenu>
                <Title
                    title="Consultar produto"
                    variant="normal"
                    render="h4"
                />
                <ProductSearch 
                    onSearch={text => navigate({
                        pathname: `/products/search`,
                        search: createSearchParams({
                            searchText: text,
                            groupId: '',
                            subGroupId: ''
                        }).toString()
                    })}
                />
            </DivMenu>
            <DivBody>                
                <AnimatePage>
                    <About />
                </AnimatePage>
                <AnimatePage>
                    <Contact />
                </AnimatePage>
                <DivMenuMobile>
                    <SearchProductMobile
                        grupos={grupos}
                    />
                </DivMenuMobile>
            </DivBody>
        </DivRoot>
    )
}

export default Home