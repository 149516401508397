import { Outlet } from "react-router-dom";
import { Header } from "./Header";
import { StatusBar } from "./StatusBar";
import styled from "styled-components";
import CategoriesList from "../../components/categories-list";
import Grupo from "../../models/grupo";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect, useState } from "react";
import { fetchSecoes } from "../../redux/slices/secao";


const DivRoot = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
    @media (max-width: 600px) {
        overflow: visible;
    }
    gap: 1px;
`
const DivBody = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    @media (max-width: 600px) {  
        overflow: visible;
    }    
`

const DivGroupMenu = styled.div`
    display: flex;
    overflow: auto;
    border-right: 1px solid ${props => props.theme.palette.warning.main};
    border-left: 1px solid ${props => props.theme.palette.warning.main};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    @media (max-width: 600px) {  
        display: none;
    }

`

const DivSubPages = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
`

const DivStatusBarLowResolution = styled.div`
    display: none;
    @media (max-height: 625px) {
        display: flex;
    }
`

const DivStatusBar = styled.div`
    display: flex;
    @media (max-height: 625px) {
        display: none;
    }
`



const Layout = () => {
    const dispatch = useAppDispatch()
    const { items, isFetching } = useAppSelector(state => state.secao)
    const [grupos, setGrupos] = useState<Array<Grupo>>([])

    useEffect(() => {
        dispatch(fetchSecoes())
    }, [])

    useEffect(() => {
        if (!items) {
            setGrupos([])
            return
        }

        const grps: Array<Grupo> = []
        items.forEach(item => (item.grupos ?? []).forEach(grp => grps.push(grp)))
        setGrupos(grps)
    }, [items])

    return (

        <DivRoot  >
            <Header />

            <DivBody >
                <DivGroupMenu>
                    <CategoriesList
                        groups={grupos}
                        isLoading={isFetching}
                    />
                </DivGroupMenu>


                <DivSubPages>

                    <Outlet />
                    <DivStatusBarLowResolution>
                        <StatusBar />
                    </DivStatusBarLowResolution>
                </DivSubPages>
            </DivBody>
            <DivStatusBar>
                <StatusBar />
            </DivStatusBar>
        </DivRoot>

    )
}

export default Layout
