import styled from "styled-components"

const DivRoot = styled.div<{color?: string}>`
    height: 2px;
    background-color: ${({theme,color}) => color?? theme.palette.common.gray};
    width: calc(100% - 10px);
    margin: 5px 0px 0px 0px;
`

interface HorizontalLineProps{
    color?: string
}

const HorizontalLine = (
    props: HorizontalLineProps
) => {
    return(
        <DivRoot color={props.color} ></DivRoot>
    )
}

export default HorizontalLine