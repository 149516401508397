import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ErrorResponse from "../../models/ErrorResponse";
import Grupo from "../../models/grupo";
import { fetchGrupoAsync } from "../../services/grupo-service";

interface InitialState{
    current?: Grupo
    isFetching?: boolean 
    fetched?: boolean 
    error?: string
}

export const fetchGrupo = createAsyncThunk(
    'grupoSlice/fetchGrupo',
    fetchGrupoAsync

)

const initialState : InitialState = {}


const grupoSlice  = createSlice({
    name: 'grupoSlice',
    initialState,
    reducers: {
        clearData: state => {
            state.current = undefined
            state.isFetching = undefined
            state.fetched = undefined
            state.error = undefined
        }
    },
    extraReducers: builder => 
        builder
            .addCase( fetchGrupo.pending, state => {
                state.fetched = false
                state.isFetching = true
            })
            .addCase( fetchGrupo.fulfilled, (state, action) => {
                state.fetched = true 
                state.isFetching = false
                state.current = action.payload
            })
            .addCase(fetchGrupo.rejected, (state, action) => {
                try{
                    const errorResponse: ErrorResponse = JSON.parse(action.error.message??"");
                    state.error = errorResponse.message;
                }
                catch
                {
                    state.error = action.error.message;
                }
                state.isFetching = false
                state.fetched = false
            })
})

export const { clearData } = grupoSlice.actions
export default grupoSlice.reducer