import styled from "styled-components"
import Produto from "../../models/produto"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faColumns, faList } from "@fortawesome/free-solid-svg-icons"
import ProductDetailRow from "../product-detail-row"
import ProductDetailCol from "../product-detail-col"
import ProductSearch from "../product-search"

const DivRoot = styled.div`
    display: flex;
    flex: 1;    
    gap: 10px;
    overflow: auto;
    flex-direction: column;
    @media (max-width: 600px) {  
        overflow: visible;
    }
`

const DivToolBar = styled.div`
    display: flex;
    gap: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.common.gray};
    border-top: 1px solid ${({ theme }) => theme.palette.common.gray};
    padding: 10px;
`

const DivToolBarProductSearch = styled.div`
    display: flex;
    gap: 10px;
    flex: 1;
`

const DivStatusBar = styled.div`
    display: flex;
    gap: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.common.gray};
    border-top: 1px solid ${({ theme }) => theme.palette.common.gray};
    padding: 10px;
`

const DivRegisterCount = styled.div`
    display: flex;
    gap: 10px;
    flex: 1;
    justify-content: flex-end;

`

const DivBody = styled.div<({ displayType: 'list' | 'column' }) >`
    display: flex;
    gap: 10px; 
    flex-direction: ${({ displayType, theme }) => displayType === 'list' ? 'column' : 'row'};
    flex: 1;    
    flex-wrap: ${({ displayType, theme }) => displayType === 'list' ? 'nowrap' : 'wrap'};
    overflow: auto;
    padding: 10px 0px 10px 0px;
    @media (max-width: 600px) {  
        overflow: visible;
    }
`

const BtnDisplayOption = styled.button<({ active?: boolean }) >`
    padding: 10px;
    cursor: pointer;
    background-color: ${({ active, theme }) => active ? theme.palette.success.main : ''};
    border: 1px solid ${({ active, theme }) => active ? theme.palette.common.black : theme.palette.common.gray};
    border-radius: 3px;
    transition: 500ms;
    &:hover{
        filter: opacity(0.5);
    }
`

interface ProductsListProps {
    items?: Array<Produto>
    onSearch: (text: string) => void
    searchText?: string
    onShowDetail: (produto: Produto) => void
    isLoading?: boolean
    displayType: 'list' | 'column'
    onChangeDisplayType: (type:'list' | 'column') => void
}

const ProductsList = (props: ProductsListProps) => {
    const { items, onSearch, searchText, onShowDetail, isLoading, onChangeDisplayType,displayType  } = props    

    return (
        <DivRoot>
            <DivToolBar>
                <BtnDisplayOption active={displayType === 'list'} onClick={e => onChangeDisplayType('list')}>
                    <FontAwesomeIcon icon={faList} />
                </BtnDisplayOption>
                <BtnDisplayOption active={displayType === 'column'} onClick={e => onChangeDisplayType('column')}>
                    <FontAwesomeIcon icon={faColumns} />
                </BtnDisplayOption>
                <DivToolBarProductSearch>
                    <ProductSearch
                        onSearch={onSearch}
                        searchText={searchText}
                        isLoading={isLoading}
                    />
                </DivToolBarProductSearch>
            </DivToolBar>
            <DivBody displayType={displayType}>
                {items?.map(produto => (
                    <>
                        {displayType == 'list' ?
                            <ProductDetailRow
                                key={produto.cadProdutoId}
                                item={produto} 
                                onShowDetail={ onShowDetail}
                            />
                            :
                            <ProductDetailCol
                                key={produto.cadProdutoId}
                                item={produto} 
                                onShowDetail={ onShowDetail}
                            />
                        }
                    </>
                ))}
            </DivBody>
            <DivStatusBar>
                <DivRegisterCount>
                    {`A consulta retornou ${(items?.length ?? 0).toLocaleString()} registro(s)`}
                </DivRegisterCount>
            </DivStatusBar>
        </DivRoot>
    )
}

export default ProductsList