import styled, { useTheme } from "styled-components"
import Contact from "../../components/contact"
import VerticalLine from "../../components/vertical-line"
import Copyright from "../../components/copyright"

const DivRoot = styled.div`
    display: flex;
    flex: 1;
    gap: 10px;
    background-color: ${({theme}) => theme.palette.primary.main};
    border: 1px solid ${ props => props.theme.palette.warning.main};
    border-top-right-radius: 10px;  
    @media (max-width: 600px) {  
        flex-direction: column;
        overflow: visible;
    }      
    
`
const DivCopyright = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 600px) {  
        align-items: center;
        border-top: 1px solid ${({theme}) => theme.palette.common.gray};
        padding: 10px;
    }  
`

export const StatusBar = () => {
    const theme = useTheme()
    return (
        <DivRoot>
            <Contact />
            <VerticalLine color={theme.palette.danger.main} />
            <DivCopyright>
                <Copyright />
            </DivCopyright>
        </DivRoot>
    )
}

