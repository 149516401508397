import { createSearchParams, useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import Title from "../../components/title"
import AnimatePage from "../../components/animatePage"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { useEffect } from "react"
import { setDisplayProductType, setSiteTitle } from "../../redux/slices/sistema"
import { fetchGrupo, clearData as clearDataGrupo } from "../../redux/slices/grupo"
import { fetchSubGrupo, clearData as clearDataSubGrupo } from "../../redux/slices/sub-grupo"
import { fetchProdutosByFilter } from "../../redux/slices/produto"
import ProductsList from "../../components/products-list"

const DivRoot = styled.div`
    display: flex;
    flex: 1;    
    flex-direction: column;
`

const DivLowResolution = styled.div`
    display: none;
    
    @media (max-height: 625px) {  
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 5px;
    }
`

const DivHighResolution = styled.div`
    display: flex;
    flex: 1;
    padding: 5px;
    flex-direction: column;
    @media (max-height: 625px) {  
        display: none;        
    }
`

const ProdutctsGroup = () => {
    const dispatch = useAppDispatch()
    const { grpId, sgrId } = useParams()
    const { current: grupo } = useAppSelector(state => state.grupo)
    const { current: subGrupo } = useAppSelector(state => state.subGrupo)
    const { items: produtos, isFetching } = useAppSelector(state => state.produto)
    const { displayProductsType } = useAppSelector(state => state.sistema)
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(setSiteTitle("ELETRÔNICA CIRCUITO - LISTAGEM DE PRODUTOS"))
        dispatch(clearDataGrupo())
        dispatch(clearDataSubGrupo())
    }, [])

    useEffect(() => {
        if (!grpId) {
            dispatch(clearDataGrupo())
            return
        }
        if (grpId) {
            dispatch(fetchGrupo(grpId))
        }
    }, [grpId])

    useEffect(() => {
        if (!sgrId) {
            dispatch(clearDataSubGrupo())
            return
        }
        if (sgrId) {
            dispatch(fetchSubGrupo(sgrId))
        }
    }, [sgrId])

    useEffect(() => {
        if (sgrId) {
            dispatch(fetchProdutosByFilter({
                subGroupId: sgrId
            }))
            return
        }
        if (grpId) {
            dispatch(fetchProdutosByFilter({
                groupId: grpId
            }))

        }
    }, [sgrId, grpId])

    return (
        <DivRoot>
            <AnimatePage>
                <DivHighResolution>
                    <Title
                        variant="large"
                        render="h2"
                        title='Listagem de produtos'
                    />
                    <Title
                        variant="normal"
                        render="h2"
                        title={`Categoria: ${grupo?.nome}${(subGrupo ? ` - ${subGrupo.nome}` : '')}`}
                    />
                </DivHighResolution>
                <DivLowResolution>
                    <Title
                        variant="normal"
                        render="h2"
                        title={`Categoria: ${grupo?.nome}${(subGrupo ? ` - ${subGrupo.nome}` : '')}`}
                    />
                </DivLowResolution>
            </AnimatePage>
            <ProductsList
                items={produtos}
                onSearch={text => navigate({
                    pathname: `/products/search`,
                    search: createSearchParams({
                        searchText: text,
                        groupId: grpId ?? '',
                        subGroupId: sgrId ?? ''
                    }).toString()
                })}
                onShowDetail={pro => navigate(`/products/id/${pro.cadProdutoId}`)}
                isLoading={isFetching}
                displayType={displayProductsType ?? 'list'}
                onChangeDisplayType={type => dispatch(setDisplayProductType(type))}
            />

        </DivRoot>
    )
}

export default ProdutctsGroup