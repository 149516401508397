import { motion } from 'framer-motion';

const animations = {
    initial: {opacity: 0, x : 100 },
    animate: {opacity: 1, x : 0 },
    exit: {opacity: 0 , x : -100}
}

interface AnimatePageProps{
    children: React.ReactNode
}

const AnimatePage = (props: AnimatePageProps) => {
    return(
        <>
            <motion.div
                variants={animations}
                initial='initial'
                animate='animate'
                exit='exit'
            >
                {props.children}
            </motion.div>
        </>
    )
}

export default AnimatePage