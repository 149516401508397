import styled, { useTheme } from "styled-components"
import HorizontalLine from "../horizontal-line"

const H2 = styled.h2<{size?: string}>`
    font-size: ${({theme,size}) => size??'1rem'};    
    padding: 0px 0 5px 0;    
    margin: 5px;
`

const H3 = styled.h3<{size?: string}>`
    font-size: ${({theme,size}) => size??'1rem'};    
    padding: 0px 0 5px 0;
    margin: 5px;
`

const H4 = styled.h4<{size?: string}>`
    font-size: ${({theme,size}) => size??'1rem'};    
    padding: 0px 0 5px 0;
    margin: 5px;
`

interface TitleProps{
    variant: 'small'|'normal'|'large'
    title:string
    render:'h2'|'h3'|'h4'
}

interface ContainerProps{
    render:'h2'|'h3'|'h4'
    size?: string
    children?: React.ReactNode
}
const Container = (props: ContainerProps) => {
    switch(props.render){
        case "h2":
            return <H2 size={props.size}>{props.children}</H2>
        case "h3":
            return <H3 size={props.size}>{props.children}</H3>
        case "h4":
            return <H4 size={props.size}>{props.children}</H4>
    }
}

const Title = (props: TitleProps) => {
    const type = props.render??'h2'
    const size = props.variant==='large'?'1.4rem':props.variant==='small'?'0.7rem':'1rem'
    const theme = useTheme()
    return(
        <Container
            render={type}   
            size={size}         
        >
            {props.title}
            <HorizontalLine color={theme.palette.danger.main} />
        </Container>
    )
}

export default Title