const ACESSO_INVALIDO = 'Acesso inválido.';
const ACESSO_NAO_AUTORIZADO = 'Acesso não autorizado.';

export const urlApi = `${process.env.REACT_APP_API ?? 'http://10.100.0.62:5293'}/api`;
export const LOCAL_STORAGE_USER_LOGIN = 'USER_LOGIN_SISCOMEX';

// async function getAuthorization() {
//     const json = localStorage.getItem(LOCAL_STORAGE_USER_LOGIN);
//     if (json) {
//         let userLogin: Usuario;
//         userLogin = JSON.parse(json);
//         return `bearer ${userLogin.token}`;
//     }
//     return '';
// }

async function handleResponse(response: Response) {
    if (response.ok) {
        if (response.status === 204) {
            return true;
        }
        return response.json();
    } else if (response.status === 400) {
        const text = await response.text();
        return Promise.reject(text);
    } else if (response.status === 401) {
        return Promise.reject(ACESSO_INVALIDO);
    } else if (response.status === 403) {
        return Promise.reject(ACESSO_NAO_AUTORIZADO);
    } else if (response.status === 404) {
        throw new Error("Recurso não localizado.");
    } else {
        throw new Error(response.statusText);
    }
}

export async function get<T>(endPoint: string, parameters: any[] | any = [], _urlApi: string | undefined = undefined): Promise<T> {
    const params = new URLSearchParams();
    if ( typeof parameters === 'object'){
        Object.keys(parameters).forEach(key => {
            params.append(key, parameters[key])
        });
    }    
    
    const url = `${_urlApi ?? urlApi}/${endPoint}${  params.toString() ? `?${params.toString()}` : ''}`;
    const headers = {
        'Content-Type': 'application/json',
        // 'Authorization': await getAuthorization()
    }
    return fetch(url, {
        method: 'GET',
        headers
    })
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch((erro: any) => {
            return Promise.reject(erro);
        })

}

export async function del<T>(endPoint: string, parameters: any[] | any = [], _urlApi: string | undefined = undefined): Promise<T> {
    const params = new URLSearchParams(parameters);
    const url = `${_urlApi ?? urlApi}/${endPoint}${(params as any)['size'] ? `?${params.toString()}` : ''}`;
    const headers = {
        'Content-Type': 'application/json',
        // 'Authorization': await getAuthorization()
    }
    return fetch(url, {
        method: 'DELETE',
        headers
    })
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch((erro: any) => {
            return Promise.reject(erro);
        })

}


export async function postFormData<V>(endPoint: string, formData: FormData): Promise<V> {
    const url = `${urlApi}/${endPoint}`;
    const headers = {
        // 'Authorization': await getAuthorization()
    }
    return fetch(url, { // Your POST endpoint
        method: 'POST',
        headers,
        body: formData // This is your file object
    }).then(
        response => //response.json() // if the response is a JSON object
        {
            if (response.status === 204) {
                return true;
            }

            if (response.ok && response.status === 200) {
                return response.json();
            }

            if (response.status === 404) {
                throw new Error("Recusro não localizado.");
            }

            if (response.status === 400) {
                return response.text().then(text => {
                    return Promise.reject(text);
                });
            }

            if (response.status === 401) {
                return Promise.reject(ACESSO_INVALIDO);
            }

            if (response.status === 403) {
                return Promise.reject(ACESSO_NAO_AUTORIZADO);
            }
            throw new Error(response.statusText);
        }
    )
        .then(data => data)
        .catch((erro: any) => {
            return Promise.reject(erro);
        })
}

export async function post<T, V>(endPoint: string, data?: T): Promise<V> {
    const url = `${urlApi}/${endPoint}`;
    const headers = {
        'Content-Type': 'application/json',
        // 'Authorization': await getAuthorization()
    }
    return fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch((erro: any) => {
            return Promise.reject(erro);
        })
}


export async function put<T, V>(endPoint: string, data?: T): Promise<V> {
    const url = `${urlApi}/${endPoint}`;
    const headers = {
        'Content-Type': 'application/json',
        // 'Authorization': await getAuthorization()
    }
    return fetch(url, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data)
    })
        .then(handleResponse)
        .then(data => {
            return data;
        })
        .catch((erro: any) => {
            return Promise.reject(erro);
        })
}