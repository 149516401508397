import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { setSiteTitle } from "../../redux/slices/sistema"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import AnimatePage from "../../components/animatePage"
import Title from "../../components/title"
import { clearCurrent, fetchProdutoById, fetchProdutoByInternalCode } from "../../redux/slices/produto"
import ProductDetailItem from "../../components/product-detail"


const DivRoot = styled.div`
    display: flex;
    flex: 1;
    padding: 5px;
    flex-direction: column;
`

const ProductsDetail = () => {
    const dispatch = useAppDispatch()
    const { current: produto } = useAppSelector(state => state.produto)
    const { id,internalCode } = useParams()


    useEffect(() => {
        dispatch(setSiteTitle("ELETRÔNICA CIRCUITO - DETALHE DO PRODUTO"))
        return () => {
            dispatch(clearCurrent())
        }
    }, [])

    useEffect(() => {
        if (!id) {
            return
        }

        dispatch(fetchProdutoById(id))

    }, [id])

    useEffect(() => {
        if (!internalCode) {
            return
        }

        dispatch(fetchProdutoByInternalCode(internalCode))

    }, [internalCode])


    return (
        <DivRoot>
            <AnimatePage>
                <Title
                    variant="large"
                    render="h2"
                    title='Detalhe do produto'
                />
                <Title
                    variant="normal"
                    render="h2"
                    title={`Produto: ${produto?.codigoPrincipal} - ${produto?.nome}`}
                />
            </AnimatePage>
            {produto &&
                <ProductDetailItem
                    item={produto}
                />
            }
        </DivRoot>
    )
}

export default ProductsDetail